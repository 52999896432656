var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "hero-container" },
        [
          _c("div", {
            staticClass:
              "absolute half-background bg-primary top-0 bottom-0 left-0 right-0 z-1 bg-center bg-cover pointer-events-none",
          }),
          _vm._v(" "),
          _c("HeroHomeSlider", {
            attrs: {
              title: "ALD Automotive, es ahora Ayvens <br>",
              "sub-title":
                "¡Oferta exclusiva de lanzamiento! Conduce un Mazda MX-30 híbrido enchufable y súbete a la movilidad eléctrica.",
              brandsActive: true,
              link: "/ofertas",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "brand-row" }, [_c("BrandsList")], 1),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "relative xl:p-0 bestOffers" },
        [
          _c(
            "BestOffers",
            {
              attrs: {
                title: "Las mejores ofertas de renting nuevo y seminuevo",
                number: 6,
              },
            },
            [
              _c("Curve", {
                staticClass: "absolute right-0 hidden top-11 xl:flex",
                attrs: {
                  width: "262px",
                  height: "218px",
                  color: "#44E1C3",
                  weight: "8px",
                  radiusTopLeft: "80px",
                },
              }),
              _vm._v(" "),
              _c("Curve", {
                staticClass: "absolute left-0 flex -bottom-20 xl:hidden",
                attrs: {
                  width: "215px",
                  height: "266px",
                  color: "#0BCBFB",
                  weight: "6px",
                  radiusBottomRight: "80px",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Advantages",
        {
          staticClass: "full-container",
          staticStyle: { background: "#0F3549" },
          attrs: {
            "is-yellow": true,
            overMan: _vm.overMan,
            title: "Ventajas del <span>renting</span> de coches",
          },
        },
        _vm._l(_vm.advantagesItems, function (item, i) {
          return _c("AdvantagesItem", {
            key: `advantage-item-${i}`,
            attrs: {
              "is-yellow": true,
              title: item.title,
              description: item.description,
              image: item.image,
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("FaqModule", {
        attrs: { items: _vm.$store.state.faqHome.home, link: "general" },
      }),
      _vm._v(" "),
      _c("DoubtsGeneral", {
        attrs: {
          title: "¿No encuentras <br> el coche que buscas?",
          "sub-title":
            "Ponte en contacto con nosotros y estaremos encantados de ayudarte.",
          formType: "both",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }