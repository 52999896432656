<template>
  <div>
    <div class="hero-container">
      <div
        class="absolute half-background bg-primary top-0 bottom-0 left-0 right-0 z-1 bg-center bg-cover pointer-events-none"
      ></div>
      <HeroHomeSlider
        title="ALD Automotive, es ahora Ayvens <br>"
        sub-title="¡Oferta exclusiva de lanzamiento! Conduce un Mazda MX-30 híbrido enchufable y súbete a la movilidad eléctrica."
        :brandsActive="true"
        link="/ofertas"
      />
      <div class="brand-row">
        <BrandsList />
      </div>
    </div>
    <div class="relative xl:p-0 bestOffers">
      <BestOffers
        class
        title="Las mejores ofertas de renting nuevo y seminuevo"
        :number="6"
      >
        <Curve
          class="absolute right-0 hidden top-11 xl:flex"
          width="262px"
          height="218px"
          color="#44E1C3"
          weight="8px"
          radiusTopLeft="80px"
        />
        <Curve
          class="absolute left-0 flex -bottom-20 xl:hidden"
          width="215px"
          height="266px"
          color="#0BCBFB"
          weight="6px"
          radiusBottomRight="80px"
        />
      </BestOffers>
      <!-- <img
        :src="overMan"
        class="absolute bottom-0 right-0 flex max-w-full translate-y-1/2 xl:hidden"
        alt
      /> -->
    </div>
    <Advantages
      :is-yellow="true"
      class="full-container"
      style="background: #0F3549;"
      :overMan="overMan"
      title="Ventajas del <span>renting</span> de coches"
    >
      <AdvantagesItem
        :is-yellow="true"
        v-for="(item, i) in advantagesItems"
        :key="`advantage-item-${i}`"
        :title="item.title"
        :description="item.description"
        :image="item.image"
      />
    </Advantages>
    <FaqModule :items="$store.state.faqHome.home" link="general" />
    <DoubtsGeneral
      title="¿No encuentras <br> el coche que buscas?"
      sub-title="Ponte en contacto con nosotros y estaremos encantados de ayudarte."
      :formType="'both'"
    />
  </div>
</template>

<script>
import backgroundImagePath1 from '~/assets/images/backgrounds/sunshine.jpg'
import backgroundImagePath2 from '~/assets/images/backgrounds/phone-call.jpg'
import backgroundImagePath3 from '~/assets/images/backgrounds/drinving-man.jpg'
import hiw from '~/assets/images/man-jumping-1.png'
import Doubts from '../components/Doubts'
import Hero from '~/components/Hero.vue'
import overMan from '~/assets/images/man-jumping-1.png'
import BestOffers from '~/components/BestOffers.vue'
import Advantages from '~/components/Advantages.vue'
import DoubtsGeneral from '~/components/DoubtsGeneral.vue'
import FaqModule from '~/components/FaqModule.vue'
import HeroHome from '~/components/HeroHome.vue'
import AdvantagesItem from '~/components/AdvantagesItem.vue'
import { getConsent } from '~/composables/getConsent'
import HeroHomeSlider from '~/components/HeroHomeSlider.vue'

const advantagesItems = [
  {
    title: 'Con o Sin entrada',
    description: 'Todo incluido; sin imprevistos y sin sorpresas.',
    image: 'con-entrada.png',
  },
  {
    title: 'A tu medida',
    description:
      'Tú eliges el plazo; desde los 12 a los 60 meses y el kilometraje que tu decidas.',
    image: 'a-tu-medida.png',
  },
  {
    title: 'Cuota fija',
    description:
      'Seguridad económica; control mensual del gasto, ya que la cuota mensual es fija.',
    image: 'cuota-fija.png',
  },
  {
    title: 'Ahorra tiempo',
    description:
      'A la hora de realizar cualquier gestión ya que Ayvens es el único interlocutor para los tramites con proveedores (talleres, servicio de cobertura de daños propios, etc).',
    image: 'ahorra-tiempo.png',
  },
  {
    title: 'Comodidad',
    description: 'Contratación 100% online.',
    image: 'comodidad.png',
  },
  {
    title: 'Ecológico',
    description:
      'Ahorro en carburante; ofreciendo flotas eficientes con las últimas tecnologías y vehículos más ecológicos.',
    image: 'ecologico.png',
  },
]

export default {
  name: 'IndexPage',
  components: {
    AdvantagesItem,
    HeroHome,
    FaqModule,
    DoubtsGeneral,
    Advantages,
    BestOffers,
    Doubts,
    Hero,
  },
  data() {
    return {
      offer: null,
      overMan,
      hiw,
      backgroundImages: [
        backgroundImagePath1,
        backgroundImagePath2,
        backgroundImagePath3,
      ],
      advantagesItems,
    }
  },
  head() {
    return {
      title: 'Renting de Coches: Particulares, Empresas y Autónomos | Ayvens',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Las mejores ofertas de renting de coches están en Ayvens. Sin entrada y con todos los servicios incluidos para particulares, empresas y autónomos.',
        },
      ],
      link: [
        { rel: 'canonical', href: process.env.DOMAIN_NAME + this.$route.path },
      ],
    }
  },
  mounted() {
    if (typeof this.$fb !== 'undefined') {
      this.$fb.enable()
    }
    if (getConsent(this.$cookies.get('CookieConsent'))) {
      this.$gtm.push({
        event: 'Datalayer',
        dataLayer: {
          tipoPagina: 'home',
          subtipoPagina: 'informacion',
          marca: '',
          modelo: '',
          motor: '',
          tipoCliente: '',
          estado: '',
          precio: '',
          routeName: 'home',
          pageType: 'PageView',
          pageUrl: '/',
          pageTitle: 'Renting Ayvens',
        },
      })
    }
  },
  methods: {
    getOffers() {
      this.$store.dispatch('offers/getHomeOffer')
    },
  },
}
</script>
<style scoped lang="scss">
.half-background{
  height: 500px;

  @media (max-width: 1440px) {
    height: 500px;
  }

  @media (max-width: 767px) {
    height: 750px;
  }

}

.hero-container {
  background: rgb(213, 241, 242)
}
.brand-row {
  z-index: 1000;
  margin-top: 40px;
  padding-bottom: 40px;
}

.bestOffers {
  background-color: #F6F6F6;
}
</style>
